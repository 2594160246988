<template>
  <div>
    <div v-for="requirement in requirementList" :key="requirement.name" class="border-b-2 pb-3 pt-3 border-gray-100">
      <div class="flex">
        <div class="mr-2 font-bold">{{ requirement.name }}</div>
        <div>
          <b-badge v-if="requirement.pastDue" variant="danger">Past due</b-badge>
          <b-badge v-if="requirement.pending" variant="secondary">In Review</b-badge>
        </div>
      </div>
      <p>
        <small>Due: {{ formatDateTime(requirement.dueDate) }}</small>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StripeRequirementDetails',
  props: ['requirements', 'futureRequirements'],
  data() {
    return {};
  },

  computed: {
    requirementList() {
      const requirementMap = new Map();
      const today = new Date();

      for (const requirement of this.requirements.past_due) {
        requirementMap.set(requirement, {
          name: requirement,
          pastDue: true,
          dueDate: today
        });
      }

      for (const requirement of this.requirements.currently_due) {
        // Requirements that are past due will have already been added
        if (!requirementMap.has(requirement)) {
          requirementMap.set(requirement, {
            name: requirement,
            pastDue: false,
            dueDate: today
          });
        }
      }

      for (const requirement of this.requirements.eventually_due) {
        if (!requirementMap.has(requirement)) {
          requirementMap.set(requirement, {
            name: requirement,
            pastDue: false,
            dueDate: new Date(this.requirements.current_deadline * 1000)
          });
        }
      }

      for (const requirement of this.requirements.pending_verification) {
        requirementMap.set(requirement, {
          name: requirement,
          pending: true,
          dueDate: today
        });
      }

      for (const requirement of [
        ...this.futureRequirements.currently_due,
        ...this.futureRequirements.past_due,
        ...this.futureRequirements.eventually_due
      ]) {
        // future requirements should not override current requirements
        if (!requirementMap.has(requirement)) {
          requirementMap.set(requirement, {
            name: requirement,
            pastDue: false,
            dueDate: new Date(this.futureRequirements.current_deadline * 1000)
          });
        }
      }

      for (const requirement of this.futureRequirements.pending_verification) {
        if (!requirementMap.has(requirement)) {
          requirementMap.set(requirement, {
            name: requirement,
            pending: true,
            dueDate: new Date(this.futureRequirements.current_deadline * 1000)
          });
        }
      }

      return Array.from(requirementMap.values()).sort((a, b) => {
        // make past due requirements appear first
        if (a.pastDue && !b.pastDue) {
          return -1;
        } else if (!a.pastDue && b.pastDue) {
          return 1;
        } else {
          // equivalent due dates should sort alphabetically
          if (a.dueDate === b.dueDate) {
            return a.name.localeCompare(b.name);
          } else {
            // finally sort by due date
            return a.dueDate.getTime() - b.dueDate.getTime();
          }
        }
      });
    }
  }
};
</script>
