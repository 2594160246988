<template>
  <div>
    <div v-for="error in errors" :key="error.requirement" class="border-b-2 pb-3 pt-3 border-gray-100">
      <div class="flex flex-col">
        <div class="mr-2">
          <span class="font-bold">Requirement:</span> <span class="font-normal">{{ error.requirement }}</span>
        </div>
        <div class="mr-2">
          <span class="font-bold">Code:</span> <span class="font-normal">{{ error.code }}</span>
        </div>
        <div class="mr-2">
          <span class="font-bold">Reason:</span> <span class="font-normal">{{ error.reason }}</span>
        </div>
      </div>
    </div>
    <div
      v-for="error in futureRequirementsErrors"
      :key="error.requirement"
      class="border-b-2 pb-3 pt-3 border-gray-100"
    >
      <div class="flex flex-col">
        <div class="mr-2">
          <span class="font-bold">Future Requirement:</span> <span class="font-normal">{{ error.requirement }}</span>
        </div>
        <div class="mr-2">
          <span class="font-bold">Code:</span> <span class="font-normal">{{ error.code }}</span>
        </div>
        <div class="mr-2">
          <span class="font-bold">Reason:</span> <span class="font-normal">{{ error.reason }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StripeErrorDetails',
  props: ['errors', 'futureRequirementsErrors']
};
</script>
